<template>
  <Loading v-if="is_Loading" />
  <div v-else class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col
        lg="5"
        class="d-none d-lg-flex align-items-center auth-login-bg p-5"
      >
        <div class="circle-bg-right" />
        <div class="login-title">
          <h3 class="title">
            {{ $t("auth.login") }}
          </h3>
        </div>
        <div class="circle-bg-left" />

        <div class="w-100 d-lg-flex image-login">
          <b-img fluid :src="sideImg" alt="Login" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="7" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="6" class="px-xl-2 mx-auto">
          <div class="text-center">
            <b-img fluid :src="logoImg" alt="logo" class="logo" />
          </div>
          <div class="mb-1 text-center">
            <h2 class="w-title">
              {{ $t("auth.welcome") }}
            </h2>
            <h3 class="des-login">
              {{ $t("g.please_enter_your_details") }}
            </h3>
          </div>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-4" @submit.prevent>
              <b-form-group
                label-for="login-email"
                class="control-input"
                :label="$t(`g.email`)"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="form_data.email"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    :placeholder="$t(`g.email`)"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group
                label-for="login-password"
                class="control-input"
                :label="$t(`g.password`)"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="form_data.password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      :placeholder="$t(`g.password`)"
                    />

                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <div class="d-flex justify-content-between mb-2">
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                  class="remember-text"
                >
                  {{ $t("auth.remember_me") }}
                </b-form-checkbox>
                <b-link :to="{ name: 'forget-password' }" class="forgot-text">
                  <small>{{ $t("auth.forgot_password") }}</small>
                </b-link>
              </div>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                class="login-btn"
                :disabled="isPending"
                @click="validationForm"
              >
                <div v-if="isPending" class="loading_" data-loading="light" />
                <span v-else>{{ $t("auth.login") }}</span>
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
        <div class="circle-bg-login" />
      </b-col>
      <!-- /Login-->
    </b-row>
    <overlay-component :is-loading="overlay_loading" />
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import toast from "@/libs/toastification";
import useJwt from "@/auth/jwt/useJwt";
import Loading from "@/components/loading/loading.vue";
import OverlayComponent from "@/components/shared/OverlayComponent";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    Loading,
    OverlayComponent,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      is_Loading: false,
      overlay_loading: false,
      form_data: {
        password: "",
        email: "",
      },
      status: "",
      sideImg: require("@/assets/images/futuer-icons/login.svg"),
      logoImg: require("@/assets/images/futuer-icons/logo.svg"),
      //
      required,
      email,
      isPending: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.overlay_loading = true;
          this.isPending = true;
          const formData = new FormData();
          formData.append("email", this.form_data.email);
          formData.append("password", this.form_data.password);

          this.$http
            .post("auth/admin/login", formData)
            .then((res) => {
              if (res.status === 200) {
                const { data } = res.data;
                useJwt.setToken(data.token);
                localStorage.setItem("userData", JSON.stringify(data.user));
                this.$store.dispatch("FETCH_DATA_USER");

                setTimeout(() => {
                  this.$router.push({ name: "home" });
                }, 300);
                toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: "Welcome",
                    icon: "CheckCircleIcon",
                    variant: "success",
                    text: "You have successfully logged in - welcome back",
                  },
                });
              }
              this.overlay_loading = false;
            })
            .catch((err) => {
              console.log(err);
              this.$helpers.handleError(err);
              this.is_loading = false;
              setTimeout(() => {
                let { user_type } = err.response.data;
                if (user_type) {
                  const userTypes = {
                    admin: process.env.VUE_APP_BASE_URL,
                    guardian: process.env.VUE_APP_PARENT_URL,
                    teacher: process.env.VUE_APP_TEACHER_URL,
                    student: process.env.VUE_APP_STUDENT_URL,
                  };
                  if (userTypes[user_type])
                    window.location.href = userTypes[user_type];
                }
              }, 500);
            })
            .finally(() => {
              this.overlay_loading = false;
              this.isPending = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "./login.scss";
</style>
